<template>
    <b-form
        class="form"
        novalidate="novalidate"
        id="st_application_form"
        ref="applicationDetailsTab"
        :class="{'st_application_form_edit': !viewMode }"
    >
        <div class="row">
            <div :class="sectionClass">
                <generate-form
                    v-if="formSchema"
                    :data="formSchema"
                    :value="formModels"
                    :viewMode="viewMode || isPaid || isSigned"
                    :requireAttachments="requireAttachments"
                    ref="generateForm"
                    @child-mounted="generatedFormMounted"
                    @modelUpdated="updateFormData"
                ></generate-form>
            </div>

            <div :class="sectionClass">
                <territorial-atlas
                    v-if="showTerritorialAtlasComponent"
                    v-show="formSchema"
                    :realEstates="realEstatesList"
                    :gisFilesLayers="gisFilesLayers"
                    :viewMode="true"
                    ref="territorialAtlas"
                    @map-mounted="generatedMapLoaded"
                    :isRealEstateTargetUsed="isRealEstateTargetUsed"
                ></territorial-atlas>

                <st-section
                    :header="$t('APPLICATION.VIEW.ATTACHMENTS_DETAILS_SECTION_TITLE')"
                    v-if="viewMode"
                >
                    <template #body>
                        <div class="row" v-if="files.length">
                            <div class="col-12" v-for="file in files" :key="file.file_id">
                                <span>
                                    {{ file.filename }}
                                    <st-button
                                        :callback="() => downloadFile(file.file_id)"
                                        variant="light"
                                        class="st-btn-text-primary"
                                    >
                                        <i class="fas fa-download"></i>
                                        {{ $t("GENERAL.ACTIONS.DOWNLOAD") }}
                                    </st-button>
                                </span>
                            </div>
                        </div>
                        <div class="row" v-if="!files.length">
                            {{ $t("GENERAL.TABLE_EMPTY_TEXT") }}
                        </div>
                    </template>
                </st-section>
            </div>
        </div>
        <div>
            <signature-details
                v-if="isDraft"
                v-can:edit="'application'"
                :viewMode="isPaid"
                :signDetails="signDetails"
                @updateSignatureType="updateSignatureType"
                @updateCertSignPackage="updateCertSignPackages"
                @downloadFiles="downloadPdfFile"
                @isSigned="applicationIsSignedOffline"
                ref="signatureDetails"
            ></signature-details>

            <payment-details
                v-if="isDraft"
                :paymentDetails="paymentDetails"
                @updatePaymentType="updatePaymentType"
                ref="paymentDetails"
            ></payment-details>

            <discount-details
                v-if="showDiscountsSection"
                :viewMode="isPaid"
                :options="discountsOptions"
                :discountDetails="discountDetails"
                ref="discountDetails"
                @updateSelectedDiscount="updateSelectedDiscount"
            ></discount-details>

            <application-summary
                :viewMode="viewMode"
                :formModels="formModels"
                :signDetails="signDetails"
                :paymentDetails="paymentDetails"
                :requiredRealEstate="requiredRealEstate"
                ref="applicationSummary"
                @child-mounted="applicationSummaryMounted"
            ></application-summary>
        </div>
    </b-form>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import GenerateForm from "@/modules/forms/components/generate-form/GenerateForm.vue";
import SignatureDetails from "@/modules/applications/components/signature/SignatureDetails";
import ApplicationSummary from "@/modules/applications/components/summary/ApplicationSummary";

export default {
    name: "ApplicationDetailsTab",
    components: {
        GenerateForm,
        SignatureDetails,
        ApplicationSummary,
        TerritorialAtlas: () => import(/* webpackChunkName: "atlas" */ "@/modules/applications/components/territorial-atlas/TerritorialAtlasCard.vue"),
        PaymentDetails: () => import("@/modules/applications/components/payment/ApplicationPayment"),
        DiscountDetails: () => import("@/modules/applications/components/discount/ApplicationDiscount"),
    },
    props: {
        applicationId: String,
        requireAttachments: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formSchema: null,
            formModels: {},
            fileStatus: {
                DRAFT: "draft",
                SIGNED: "signed",
                GENERATED: "generated",
                RESOLVED: "resolved",
                SEALED: "sealed"
            },
            applicationDraftStatus: "draft",
            initialFormModels: {},
            discountsOptions: null,
            isRealEstateTargetUsed: false,
            requiredRealEstate: false,
            hasGisAttachments: false,
        };
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
            fileToken: "applications/form/fileToken",
            applications: "applications/list/rows",
            loading: "shared/loading",
            files: "applications/form/combinedFiles",
        }),
        viewMode() {
            return this.record?.status !== this.applicationDraftStatus || this.record?.payment_initiated_public;
        },
        isLoading() {
            return (this.loading["applications/update"] || this.loading["applications/find"]);
        },
        sectionClass() {
            return this.isDraft ? "col-12" : "col-6";
        },
        isPaid() {
            return this.record?.is_paid;
        },
        isSigned() {
            return this.record?.is_signed;
        },
        isDraft() {
            return this.record?.status === this.applicationDraftStatus;
        },
        gisFilesLayers() {
            return this.record?.gis_files_layers;
        },
        realEstatesList() {
            if (!this.formModels?.real_estate_target?.length) return [];
            return this.formModels.real_estate_target?.filter((el) => el?.coordinates?.length);
        },
        signDetails() {
            return (
                this.record?.sign_details ?? {
                    isOnline: true,
                    package: null,
                }
            );
        },
        paymentDetails() {
            return (
                this.record?.payment_details ?? {
                    payment_type: "CARD",
                }
            );
        },
        discountDetails() {
            return this.record?.discount_details;
        },
        showTerritorialAtlasComponent() {
            return this.requiredRealEstate || this.hasGisAttachments;
        },
        showDiscountsSection() {
            return this.formSchema && this.discountsOptions?.length && this.isDraft;
        },
    },
    methods: {
        ...mapActions({
            findFiles: "applications/form/findFiles",
            doFindApplicationType: "applications/form/findApplicationType",
            doFindForm: "applications/form/findForm",
            doUpdate: "applications/form/update",
            initializeOfflineSigning: "applications/form/initializeOfflineSigning",
            updateOfflineSignDownloadStatus: "applications/form/updateOfflineSignDownloadStatus",
            setComputedTaxFields: "applications/form/doSetComputedTaxFields",
            resetComputedTaxFields:
                "applications/form/doResetComputedTaxFields",
            downloadFileContent: "shared/downloadFileContent",
        }),
        async downloadFile(id) {
            // window.open(`${window.VUE_APP_API_URL}/files/${id}?token=${this.fileToken}`, "_blank");

            const url = `${window.VUE_APP_API_URL}/files/${id}`;
            const fileContent = await this.downloadFileContent({url, token: this.fileToken});

            const _url = window.URL.createObjectURL(fileContent);
            window.open(_url, "_blank").focus();
        },
        updateSignatureType(isOnline) {
            this.record.sign_details = {
                isOnline,
            };
            this.$emit("updateSignatureType", isOnline);
        },
        updateCertSignPackages(item) {
            this.record.sign_details = {
                ...this.record.sign_details,
                package: item,
            };
            this.$emit("updateCertSignPackages", item);
        },
        updatePaymentType(paymentType) {
            this.record.payment_details = {
                payment_type: paymentType,
            };
            this.$emit("updatePaymentType", paymentType);
        },
        updateSelectedDiscount(discountId) {
            const discountDetails = this.discountsOptions.find(option => option.id === discountId);
            this.record.discount_details = discountDetails
            this.$emit("updateDiscountDetails", discountDetails);
        },
        async downloadArchive() {
            this.$emit("componentEvent", "downloadArchive");
        },
        generatedFormMounted() {
            this.$emit("form-mounted");
        },
        generatedMapLoaded() {
            this.$emit("map-mounted");
        },
        applicationSummaryMounted() {
            this.$emit("app-summary-mounted");
        },
        computeTaxFields(data, key) {
            if (this.is_tax_category_conditional && this.tax_category_conditions) {
                const TAX_RELATED_FIELDS = Object.keys(this.tax_category_conditions );
                if (TAX_RELATED_FIELDS.includes(key)) {
                    const payload = {
                        [key]: data,
                    };
                    this.setComputedTaxFields(payload);
                }
            }
        },
        clearFormData(data) {
            this.formModels = {...this.formModels, ...data.form};
        },
        updateFormData(data, key, realEstateTargetUsed) {
            this.initialFormModels = Object.assign({}, this.formModels);
            Vue.set(this.formModels, key, data);
            this.isRealEstateTargetUsed = realEstateTargetUsed;
            if (this.formModels[key]) {
                this.syncFiles(data, key, this.formModels);
            }
            this.computeTaxFields(data, key);
        },
        async downloadPdfFile() {
            this.$emit("downloadPdfFile");
        },
        async applicationIsSignedOffline() {
            this.$emit("isSigned");
        },
        syncFiles(data, key, models) {
            //check if there are files
            const location = data?.length > 0 ? data : this.initialFormModels[key];
            if (Array.isArray(location)) {
                let hasFileKey = location?.filter((el) => el?.file_id);

                if (hasFileKey?.length > 0) {
                    const removedFiles = this.initialFormModels[key]?.filter(x => !models[key].map((el)=> el.file_id).includes(x.file_id));
                    if (key === 'attachments') {
                        removedFiles?.forEach((file) => {
                            for (let index in models) {
                                if (index !== 'attachments' && Array.isArray(models[index]) && file?.file_id) {
                                    const fileIndex = models[index].findIndex((el) => el?.file_id === file.file_id);
                                    if (fileIndex !== -1) {
                                        this.$delete(models[index], fileIndex);
                                    }
                                }
                            }
                        });
                    } else {
                        removedFiles?.forEach((file) => {
                            if (file?.file_id) {
                                const fileIndex = models['attachments'].findIndex((el) => el?.file_id === file.file_id);
                                if (fileIndex !== -1) {
                                    this.$delete(models['attachments'], fileIndex);
                                    Vue.set(this.record, 'identification_files', models['attachments']);
                                }
                            }
                        })
                    }
                }
            }
        }
    },
    async created() {
        if (this.record?.application_type_id) {
            this.formModels = this.record.form;
            this.formModels.application_type_id = this.record.application_type_id;
            const {
                form_citizen_id,
                tax_category_conditions,
                is_tax_category_conditional,
                discounts,
            } = await this.doFindApplicationType(this.record.application_type_id);
            const formData = await this.doFindForm(form_citizen_id);
            this.formSchema = formData.schema;

            this.hasGisAttachments = !!formData.schema.list.find(item => item.type === 'gis-attachments');
            const show = !!formData.schema.list.find(item => item.type === 'real-estate-list');
            this.requiredRealEstate = show;
            if (!show) {
                this.generatedMapLoaded();
            }

            this.discountsOptions = discounts ?? null;

            if (is_tax_category_conditional) {
                this.tax_category_conditions = tax_category_conditions;
                this.is_tax_category_conditional = is_tax_category_conditional;

                const keys = Object.keys(tax_category_conditions);

                let conditionalKey;
                let conditionalData;
                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];
                    const data = this.formModels[key];
                    if (data) {
                        conditionalKey = key;
                        conditionalData = data;
                        break;
                    }
                }

                if (conditionalKey) {
                    this.computeTaxFields(conditionalData, conditionalKey);
                } else {
                    this.resetComputedTaxFields();
                }
            }
        }
    },
    watch: {
        "record.form": function(newVal) {
            this.formModels = { ...this.formModels, ...newVal };
        },
    },
};
</script>
